import React from "react";
import { useTranslation } from "react-i18next";
import type { SelectValue } from "clutch/src/Select/Select.jsx";
import { Select } from "clutch/src/Select/Select.jsx";
import { TextInput } from "clutch/src/TextInput/TextInput.js";

import type { SortDirections } from "@/game-eft/utils/use-flea-market-filters.mjs";
import { SortSelectOptions } from "@/game-eft/utils/use-flea-market-filters.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";

interface FleaCategoryFilterProps {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  sortDirection: SortDirections;
  setSortDirection: (direction: SortDirections) => void;
}

export default function FleaCategoryFilters({
  searchTerm,
  setSearchTerm,
  sortDirection,
  setSortDirection,
}: FleaCategoryFilterProps) {
  const { t } = useTranslation();

  return (
    <div className="flex row justify-between">
      <TextInput
        defaultValue={searchTerm}
        placeholder={t("common:search.search", "Search")}
        onValueChange={setSearchTerm}
        Icon={SearchIcon}
      />
      <Select
        options={Object.values<SelectValue<SortDirections>>(SortSelectOptions)}
        selected={sortDirection}
        onChange={setSortDirection}
      />
    </div>
  );
}
